// @ts-nocheck

import React from "react";
import Layout from "../atom/layout";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { getFlightLogs, flightLogsEndpoint as cacheKey } from "../../api/fileApi";
import useSWR from 'swr';
import axios from "axios";
import { DeleteIcon, EditIcon } from "../atom/icons";

const FlightTestTracker: React.FC = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { isLoading, error, data: logFiles, mutate } = useSWR(cacheKey, getFlightLogs, {
        onError(err) {
            if (axios.isAxiosError(err)) {
                if (err.response?.status === 401) {
                    console.log('Unauthorized');
                    navigate('/login');
                }
            }
        }
    });

    return (
        <>
            <Layout />
            <div className="p-4 sm:ml-64 mt-16">
                <div className="p-4 rounded-lg dark:border-gray-700">
                    <div className="flex justify-between items-center h-16 mb-4 rounded bg-gray-50 dark:bg-gray-800">
                        <div className="flex">
                            <div className="text-grey-500 hover:text-grey-100 font-semibold pl-4">Flight Test Simulation</div>
                        </div>
                    </div>
                    <div className="grid grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 mb-4">
                        {logFiles && logFiles?.data.map((item) => (
                            <Link key={item.id} to={`/web-apps/flight-test-tracker/${item.id}`} className="relative block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                                <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">{item.title}</h5>
                                <p className="text-xs font-extralight">Last Updated</p><span className="text-sm font-semibold">{new Date((item.updated_at ? item.updated_at : item.created_at) + 'Z').toLocaleString()}</span>
                            </Link>
                        ))}
                        <Link key="new" to="/web-apps/flight-log/new" className="rounded-full flex items-center block p-6 text-4xl w-fit text-stone-400 bg-white hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                            <h1>Add +</h1>
                        </Link>
                    </div>
                </div>
            </div >
        </>
    )
}

export default FlightTestTracker;
