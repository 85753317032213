
import React, { useEffect } from "react";
import Layout from "../atom/layout";
import { useParams, useNavigate } from 'react-router-dom';
import { dashboardDetails, dashboardEndpoint as cacheKey } from "../../api/dashboardApi";
import useSWR from 'swr';
import { isAxiosError } from "axios";

const TeamDetail: React.FC = () => {
    const { name } = useParams();
    const navigate = useNavigate();
    const [team, setTeam] = React.useState(null as any);

    const {
        isLoading, error, data: dashboardContent, mutate
    } = useSWR(cacheKey, dashboardDetails, {
        onError(err) {
            if (isAxiosError(err)) {
                if (err.response?.status === 401) {
                    console.log('Unauthorized');
                    navigate('/login');
                }
            }
        }
    });
    useEffect(() => {
        if (name && dashboardContent?.data?.teams) {
            const content = dashboardContent?.data?.teams;
            setTeam(content[name]);
        }
    }, [dashboardContent])
    return (
        <>
            <Layout />
            <div className="p-4 sm:ml-64">
                {team && <div className="p-4 rounded-lg dark:border-gray-700 mt-16">
                    <h1 className="mb-4 text-6xl font-semibold text-stone-500">{team.title}</h1>
                    <p className="mb-4 text-lg text-gray-600">{team.description}</p>
                </div>}
            </div>
        </>
    )
}

export default TeamDetail;