// @ts-nocheck

import React, { useCallback, useRef, useState } from 'react';
import { useLocation, useParams, Link, useNavigate } from 'react-router-dom'
import ReactFlow, {
    addEdge,
    MiniMap,
    Controls,
    ControlButton,
    Panel,
    Background,
    useNodesState,
    useEdgesState,
    MarkerType,
    ConnectionLineType,
    ConnectionMode,
} from 'reactflow';
import ShapeNode from '../nodes/shapeNode';
import { deleteFlowchart, flowchartsEndpoint as cacheKey } from '../../api/dashboardApi';
import 'reactflow/dist/style.css';
import { toPng } from 'html-to-image';
import { useSWRConfig } from 'swr';
import exportIcon from '../../assets/export-16.png';
import { SHAPES } from '../atom/shape';
import { ArrowLeftIcon, DeleteIcon, EditIcon } from '../atom/icons';
import Modal from '../molecules/modals';

const actionBtnClass = "p-2 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 shadow-sm disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 font-semibold"


const nodeTypes = {
    shape: ShapeNode
}

const ActionPanel = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { mutate } = useSWRConfig()

    const { name } = useParams();

    const deletePage = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            await deleteFlowchart(name);
            setLoading(false);

            // revalidate pages, called @ flowchartMain.tsx
            mutate(cacheKey);
            navigate('/flowcharts');
        } catch (error) {
            console.error('An error occurred', error);
            setLoading(false);
        }
    }

    const goBack = useCallback(() => {
        navigate(-1);
    }, []);


    return (
        <Panel position="top-right">
            <div className='inline-flex rounded-lg shadow-sm'>
                <Link to={`/flowcharts/${name}/edit`} className={actionBtnClass + " bg-transparent text-grey-500 hover:bg-gray-100"}><EditIcon width={24} height={24} /></Link>
                <button onClick={(event) => setIsModalOpen(true)} className={actionBtnClass + " text-red-400 hover:bg-red-100 border"}>
                    <DeleteIcon />
                </button>
                <button onClick={goBack} className={actionBtnClass}>
                    <ArrowLeftIcon />
                </button>
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onConfirm={deletePage} />

        </Panel >
    )
}


interface NodesEdgesProps {
    initialNodes: any[],
    initialEdges: any[],
    downloadable?: boolean,
    actionPanel?: boolean
}

interface Shape {
    icon: React.FC<{}>,
    component: React.FC<{}>,
    color: string
}

interface Shapes {
    [key: string]: Shape
}

const getDefaultColor = (type: string) => SHAPES[type].color;
const onInit = (reactFlowInstance: any) => console.log('flow loaded:', reactFlowInstance);

const Chart = ({ initialNodes = [], initialEdges = [], downloadable = true, actionPanel }: NodesEdgesProps) => {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const flowRef = useRef(null);
    const location = useLocation();


    return (
        <>
            <div className="sm:ml-64 mt-16">
                <div className="rounded-lg dark:border-gray-700">
                    <div style={{ width: "100%", height: "calc(100vh - 4rem)" }}>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onInit={onInit}
                            nodeTypes={nodeTypes}
                            nodesDraggable={false}
                            connectionMode={ConnectionMode.Loose}
                            connectionLineType={ConnectionLineType.SmoothStep}
                            proOptions={{ account: "paid-pro", hideAttribution: true }}
                            attributionPosition="top-right"
                            deleteKeyCode={null}
                            downloadable
                            ref={flowRef}
                            fitView
                        >
                            <MiniMap
                                nodeStrokeColor={(node) => {
                                    if (node?.data?.type === "subflow") return "currentColor";
                                    return getDefaultColor(node?.data?.type)
                                }}
                                nodeColor={(node) => getDefaultColor(node?.data?.type)}
                                zoomable pannable />
                            <Controls showInteractive={false}>
                                {downloadable && <ControlButton onClick={() => {
                                    if (flowRef.current === null) return
                                    toPng(flowRef.current, {
                                        filter: node => !(
                                            node?.classList?.contains('react-flow__minimap') ||
                                            node?.classList?.contains('react-flow__controls') ||
                                            node?.classList?.contains('react-flow__panel')
                                        ),
                                    }).then(dataUrl => {
                                        const a = document.createElement('a');
                                        a.setAttribute('download', `flowchart-${location.pathname.split('/').pop()}.png`);
                                        a.setAttribute('href', dataUrl);
                                        a.click();
                                    });
                                }}>
                                    <img src={exportIcon} alt="Export" style={{ padding: '0.1em' }} />
                                </ControlButton>}
                            </Controls>
                            <Background color="#aaa" gap={16} />
                            {actionPanel && <ActionPanel />}
                        </ReactFlow>
                    </div>
                </div>
            </div >
        </>
    );
};


export default Chart;
