// @ts-nocheck

import React, { useCallback, useEffect } from 'react';
import Layout from '../atom/layout';
import { Link, useNavigate } from "react-router-dom";
import { getFlowchartPages, flowchartsEndpoint as cacheKey } from "../../api/dashboardApi";
import { APPS_BASE_URL, flowChartsHost } from '../../constants/urls';
import useSWR from 'swr';
import axios from "axios";


const FlowchartsMain: React.FC = () => {
    const navigate = useNavigate();
    const [title, setTitle] = React.useState<string>('');
    const [parent, setParent] = React.useState<string>(false);

    const [parentOnly, setParentOnly] = React.useState<boolean>(true);
    const [showTitleField, setShowTitleField] = React.useState<boolean>(false);
    const [filteredPages, setFilteredPages] = React.useState<any>(null);
    const [pages, setPages] = React.useState<any>(null);
    const [parentOptions, setParentOptions] = React.useState<any>(null);

    const toggleShowTitleField = () => {
        setShowTitleField(!showTitleField);
    }

    const handleTitleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setTitle(e.target.value);
        }, [],
    )

    const handleParentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        ['null', '', 'None', null].includes(event.target.value) ? setParent(null) : setParent(event.target.value);
    };

    const handleFilterChange = (event) => {
        if (event.target.value === 'true') {
            setParentOnly(true);
        } else if (event.target.value === 'false') {
            setParentOnly(false);
        }
        else {
            setParentOnly(false);
        }
    };


    const { isLoading, error, data, mutate } = useSWR(cacheKey, getFlowchartPages, {
        onError(err) {
            if (axios.isAxiosError(err)) {
                if (err.response?.status === 401) {
                    console.log('Unauthorized');
                    navigate('/login');
                }
            } else {
                console.error('An unknown error occurred', err);
            }
        }
    });
    useEffect(() => {
        setPages(data?.data);
        if (data) {
            let pages: [] = data.data;
            let filtered = pages.filter((page) => {
                if (parentOnly) {
                    return page.parent === null
                } else {
                    return true;
                }
            });
            setFilteredPages(filtered);
        }
    }, [data, parentOnly])

    useEffect(() => {
        if (pages) {
            let parentOptions = pages.map((page) => {
                if (page.parent == null) {
                    return <option key={page.id} value={page.slug}>{page.title}</option>
                }
            });
            parentOptions.unshift(<option key="null" value={null}>None</option>)
            setParentOptions(parentOptions);
        }
    }, [pages])

    return (
        <>
            <Layout />
            <div className="p-4 sm:ml-64 mt-16">
                <div className="p-4 rounded-lg dark:border-gray-700">
                    {!showTitleField && (
                        <div className="flex justify-between items-center h-24 mb-4 rounded bg-gray-50 dark:bg-gray-800">
                            <div className="flex">
                                <button
                                    to='/flowcharts/new'
                                    onClick={() => toggleShowTitleField()}
                                    className='ml-4 text-grey-500 hover:bg-gray-100 border font-semibold py-2 px-4 rounded'
                                >
                                    Create Graph
                                </button>
                                {/* <Link
                                    to={`${APPS_BASE_URL}/flowcharts/new`}
                                    target='_blank'
                                    className='ml-4 text-grey-500 hover:bg-gray-100 border font-semibold py-2 px-4 rounded'
                                >
                                    Create Mermaid Graph
                                </Link> */}
                            </div>
                            <div className="ml-4 text-grey-500 border font-semibold py-2 px-4 rounded">
                                <label htmlFor="parentOnly">View</label>
                                <select id="filter" value={parentOnly} onChange={handleFilterChange}>
                                    <option value={true}>Main</option>
                                    <option value={false}>All</option>
                                </select>
                            </div>
                        </div>
                    )}
                    {
                        showTitleField && <>
                            <div className='flex items-center h-24 mb-4 rounded bg-gray-50 dark:bg-gray-800'>
                                <input type="text" value={title} onChange={handleTitleChange} placeholder='Title' className={`ml-4 text-grey-500 border font-semibold py-2 px-4 rounded ${showTitleField ? 'block' : 'hidden'}`} />
                                <div className='ml-4 text-grey-500 border font-semibold py-2 px-4 rounded'>

                                    <label htmlFor="parent">Parent:</label>
                                    <select id="parent" value={parent} onChange={handleParentChange}>
                                        {parentOptions}
                                    </select>
                                </div>
                                <Link to={`/flowcharts/new?title=${title}&parent=${parent}`} className='ml-4 text-white bg-indigo-600 hover:bg-indigo-500 border font-semibold py-2 px-4 rounded'>
                                    Add
                                </Link>
                                <button onClick={() => toggleShowTitleField()} className='ml-4 text-grey-500 hover:bg-gray-100 border font-semibold py-2 px-4 rounded'>Cancel</button>
                            </div>
                        </>
                    }
                    <div className="grid grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 mb-4">
                        {isLoading && <div className="animate-pulse bg-gray-200 dark:bg-gray-700 h-48 rounded-lg"></div>}
                        {filteredPages && filteredPages.map(
                            (page) => {
                                const { title, parent, resource, route } = page
                                let path = ""
                                let target = undefined
                                if (resource.includes('mmd')) {
                                    path = `${flowChartsHost}${route}`
                                    target = "_blank"
                                } else if (resource.includes('json')) {
                                    let _route = route.replace("/", "");
                                    path = `/flowcharts/${_route}`
                                } else {
                                    console.error('Invalid resource type')
                                    return null;
                                }
                                return (
                                    <Link key={page.id} to={path} target={target} className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                                        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">{title}</h5>
                                        {/* {content[key].description && <p className="font-normal text-gray-700 dark:text-gray-400">{content[key].description}</p>} */}
                                    </Link>
                                )
                            }
                        )}
                    </div>
                </div >
            </div >
        </>
    );
};


export default FlowchartsMain;