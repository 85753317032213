import axios, { AxiosError } from 'axios';
import { BACKEND_URL } from '../constants/urls';

const loginApi = axios.create({
    baseURL: BACKEND_URL,
    headers: {
        "Content-Type": "multipart/form-data"
    }
})

const backendApi = axios.create({
    baseURL: BACKEND_URL,
});


export const loginUrlEndpoint = '/login';

export const login = async (formData: FormData) => {
    try {
        const response = await loginApi.post(loginUrlEndpoint, formData);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const err = error as AxiosError
            console.log('login failed:', error?.response);
            return error.response;
        } else {
            console.error('login failed:', error);
        }
    }
}

export const refreshUrlEndpoint = '/refresh';

export const refresh = async () => {
    try {
        const response = await backendApi.post(refreshUrlEndpoint);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const err = error as AxiosError
            console.log('refresh failed:', error?.response);
            return error.response;
        } else {
            console.error('refresh failed:', error);
        }
    }
}


export const handle401Error = async (error: AxiosError, afterRefreshSuccess: any, afterRefreshFail: any) => {
    console.log('handle401Error: Refreshing', error);
    const response = await refresh();
    if (response && response.status === 200) {
        const newAccessToken = response.data.access_token;
        const newRefreshToken = response.data.refresh_token;

        const tokens = localStorage.getItem('auth');
        if (tokens) {
            localStorage.setItem('auth', JSON.stringify({ ...JSON.parse(tokens), access_token: newAccessToken, refresh_token: newRefreshToken }));
            backendApi.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
            afterRefreshSuccess();
        } else {
            afterRefreshFail();
        }
    } else {
        afterRefreshFail();
    }
}