import React, { useCallback, useEffect, useState } from "react";
import Layout from "../atom/layout";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useSWR from "swr";
import axios from "axios";
import { getDemos, addDemo, getDemo, demoEndpoint as cacheKey } from "../../api/dashboardApi";
import { DemoLinkCards } from "../molecules/LinkCards";
import { slugify } from "../../helper/utils";
import DemoModal from "../organisms/editDemoModal";
import { ArrowLeftIcon } from "../atom/icons";


const Demos: React.FC = () => {
    const { pathname } = useLocation();
    let pageTitle = pathname === '/teams' ? 'Teams' : 'Demos';
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();
    let parent = searchParams.get('parent');
    let parentTitle = searchParams.get('parentTitle');


    const initialData = { title: '', description: '', parent: parent || 'null', url: '', url_type: 'external' }

    const [demo, setDemo] = useState(initialData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [user, setUser] = useState(null as any);

    const {
        isLoading, error, data: demoContent, mutate
    } = useSWR([cacheKey, parent || "null"], getDemos, {
        onError(err) {
            if (axios.isAxiosError(err)) {
                if (err.response?.status === 401) {
                    console.log('Unauthorized');
                    navigate('/login');
                }
            }
        }
    });
    const content = demoContent?.data;

    const showDemoForm = () => {
        setIsModalOpen(true);
    }
    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const loginDetails = JSON.parse(localStorage.getItem('auth') || '{}');
        setUser(loginDetails.user);
    }, []);

    const onDemoBreadcrumbClick = async (id: string) => {
        const response = await getDemo(id);
        const demo = response.data;
        navigate(`${pathname}?parent=${demo.parent}`);
    }

    return (
        <>
            <Layout />
            <div className="p-4 sm:ml-64 mt-16">
                <div className="p-4 rounded-lg dark:border-gray-700">
                    <div className="flex justify-between items-center h-16 mb-4 rounded bg-gray-50 dark:bg-gray-800">
                        {(parent && parent !== 'null') ? <div className="flex">
                            <div onClick={() => onDemoBreadcrumbClick(parent || 'null')} className='text-grey-500 hover:text-grey-100 font-semibold cursor-pointer pl-4'>
                                <ArrowLeftIcon />
                            </div>
                            <span className="mx-2 text-gray-400">/</span>
                            <div>{parentTitle ? parentTitle : parent}</div>
                        </div> : <div className="text-grey-500 hover:text-grey-100 font-semibold pl-4">{pageTitle}</div>}

                        {user?.role === 'admin' && <button onClick={showDemoForm} className="ml-4 text-grey-500 hover:bg-gray-100 border font-semibold py-2 px-4 rounded">+ Demo</button>}
                    </div>
                    <DemoLinkCards readOnly={user?.role !== 'admin'} content={content} loading={isLoading} />
                    <DemoModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        initialData={{ ...demo, parent: parent || 'null' }}
                        demos={content}
                    />
                </div>
            </div>

        </>
    );
}

export default Demos;