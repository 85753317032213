

import React, { useCallback } from 'react';
import Layout from '../atom/layout';
import Shapes from '../pages/shapes';
import { getFlowchartResource } from '../../api/dashboardApi';
import { flowChartsHost } from '../../constants/urls';
import { useParams, useNavigate } from 'react-router-dom';
import { isAxiosError } from "axios";
import useSWR from 'swr';

const EditCharts = () => {
    const { name } = useParams();
    const navigate = useNavigate();
    if (name) {
        const resourceUri = `${flowChartsHost}/resources/${name}.json`;
        const { isLoading, error, data, mutate } = useSWR(resourceUri, getFlowchartResource, {
            onError(err) {
                if (isAxiosError(err)) {
                    if (err.response?.status === 401) {
                        console.log('Unauthorized');
                        navigate('/login');
                    }
                } else {
                    console.error('An unknown error occurred', err);
                }
            }
        });

        if (data?.data) {
            const { nodes, edges } = data?.data;
            return (
                <>
                    <Layout />
                    <Shapes initialNodes={nodes} initialEdges={edges} />
                </>
            );
        }

    }

    return (
        <>
            <Layout />
            <Shapes />
        </>
    );
};

export default EditCharts;