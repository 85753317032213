// @ts-nocheck

import React, { useState } from "react";
import Layout from "../atom/layout";
import { useParams, useNavigate, Link } from "react-router-dom";
import { uploadFile, getFlightLogDetail, deleteFlightLog, allFilesEndpoint as cacheKey } from "../../api/fileApi";
import useSWR from 'swr';
import axios from "axios";
import FileUpload from "../atom/fileUpload";
import { flightTestTrackerUrl, BACKEND_URL } from "../../constants/urls";
import FlightLogTable from "../organisms/flightLogTable";
import Modal from "../molecules/modals";


const FlightTestTrackerDetail: React.FC = () => {
    const { logId } = useParams();

    const navigate = useNavigate();
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { isLoading, error, data: logDetail, mutate } = useSWR([logId, cacheKey], getFlightLogDetail, {
        onError(err) {
            if (axios.isAxiosError(err)) {
                if (err.response?.status === 401) {
                    console.log('Unauthorized');
                    navigate('/login');
                }
            }
        }
    });

    const handleUploadFile = async (file: any) => {
        try {
            let prefix = logDetail?.data.bucket_prefix;
            const response = await uploadFile(file, prefix);
            setIsFileUploading(true);
        } catch (error) {
            console.log(error);
        } finally {
            setIsFileUploading(false);
            mutate();
        }

    }

    const handleDelete = async () => {
        try {
            await deleteFlightLog(logDetail?.data.id);
            navigate("/web-apps/flight-test-tracker");
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Layout />
            <div className="p-4 sm:ml-64 mt-16">
                <div className="p-4 rounded-lg dark:border-gray-700">
                    <div className="flex justify-between items-center h-16 mb-4 rounded bg-gray-50 dark:bg-gray-800">
                        <div className="flex">
                            <div className="text-grey-500 hover:text-grey-100 font-semibold pl-4">Flight Log
                                <span className="px-4 font-normal">{logDetail?.data.title}</span>
                            </div>
                        </div>
                        {logDetail && logDetail?.data &&
                            < div >
                                <Link to={`/web-apps/flight-log/${logDetail?.data.id}/edit`} className='ml-4 hover:bg-grey-500 border font-semibold py-2 px-4 rounded'>
                                    Edit
                                </Link>
                                <button onClick={() => setIsModalOpen(true)} className='ml-2 leading-5 text-red-600 hover:bg-grey-500 border font-semibold py-2 px-4 rounded'>
                                    Delete
                                </button>
                            </div>
                        }
                    </div>
                    {logDetail?.data && <Modal
                        key={logDetail?.data.id}
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        onConfirm={handleDelete}
                        description="The log will be deleted but files will remain in storage. This action cannot be undone."
                    >
                    </Modal>}
                    <FlightLogTable logDetail={logDetail?.data} mutate={mutate} />
                    <FileUpload isFileUploading={isFileUploading} onFileUpload={handleUploadFile} />
                </div>
            </div >
        </>
    )
}

export default FlightTestTrackerDetail;
