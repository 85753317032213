
import React from "react";
import { createBrowserRouter, createHashRouter, Navigate } from "react-router-dom";

import Layout from "./components/atom/layout";
import PageTitle from "./components/atom/pageTitle";

import Dashboard from './components/pages/dashboard';
import Login from './components/pages/login';
import ErrorPage from './components/pages/error';
import ProtectedRoute from './components/atom/protectedRoute';
import Flowcharts from "./components/pages/flowcharts";
import FlowchartsMain from "./components/pages/flowchartsMain";
import EditCharts from "./components/pages/editCharts";
import UpdateDashboard from "./components/pages/updateDashboard";
import Demos from "./components/pages/demos";
import Shapes from "./components/pages/shapes";
import TeamDetail from "./components/pages/teamDetail";
import Teams from "./components/pages/teamMain";
import FlightTestTracker from "./components/pages/flightTestTrackerMain";
import FlightTestTrackerDetail from "./components/pages/flightTestTrackerDetail";
import EditFlightLog from "./components/pages/editFlightLog";


const router = createHashRouter([
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    // element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/teams" replace />,
      },
      {
        id: "teams",
        path: "/teams",
        element: (
          <>
            <ProtectedRoute />
            <PageTitle title="Teams" />
            <Teams />
          </>
        ),
      },
      {
        id: "mechanical",
        path: "/teams/:name",
        element: (
          <>
            <ProtectedRoute />
            <PageTitle title="Team" />
            <TeamDetail />
          </>
        )
      }
    ]
  },
  {
    path: "/update-dashboard",
    // element: <Login />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: "update-dashboard",
        path: "/update-dashboard",
        element: (
          <>
            <ProtectedRoute />
            <PageTitle title="Customize Dashboard" />
            <UpdateDashboard />
          </>
        ),
      }
    ]
  },
  {
    path: "/web-apps",
    // element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: "web-apps",
        path: "/web-apps",
        element: (
          <>
            <ProtectedRoute />
            <PageTitle title="Web Apps" />
            <Dashboard />
          </>
        ),
      },
      {
        id: "web-apps-flight-test-tracker",
        path: "/web-apps/flight-test-tracker",
        element: (
          <>
            <ProtectedRoute />
            <PageTitle title="Flight Test Tracker" />
            <FlightTestTracker />
          </>
        ),
      },
      {
        id: "web-apps-flight-log-new",
        path: "/web-apps/flight-log/new",
        element: (
          <>
            <ProtectedRoute />
            <PageTitle title="Flight Test Tracker" />
            <EditFlightLog />
          </>
        ),
      },
      {
        id: "web-apps-flight-log-edit",
        path: "/web-apps/flight-log/:logId/edit",
        element: (
          <>
            <ProtectedRoute />
            <PageTitle title="Flight Test Tracker" />
            <EditFlightLog />
          </>
        ),
      },
      {
        id: "web-apps-flight-test-tracker-detail",
        path: "/web-apps/flight-test-tracker/:logId",
        element: (
          <>
            <ProtectedRoute />
            <PageTitle title="Flight Test Tracker" />
            <FlightTestTrackerDetail />
          </>
        ),
      }
    ]
  },
  {
    path: "/flowcharts",
    // element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: "flowcharts",
        path: "/flowcharts",
        element: (
          <>
            <ProtectedRoute />
            <PageTitle title="Flow Charts" />
            <FlowchartsMain />
          </>
        ),
      },
    ]
  },
  {
    path: "/flowcharts/:name",
    // element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: "flowcharts-charts",
        path: "/flowcharts/:name",
        element: (
          <>
            <ProtectedRoute />
            <PageTitle title="Flowcharts charts" />
            <Flowcharts />
          </>
        ),
      },
    ]
  },
  {
    path: "/flowcharts",
    // element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: "flowcharts-new",
        path: "/flowcharts/new",
        element: (
          <>
            <ProtectedRoute />
            <PageTitle title="Flowcharts new" />
            <Shapes />
          </>
        ),
      },
    ]
  },
  {
    path: "/flowcharts/:name/edit",
    // element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: "flowcharts-name-edit",
        path: "/flowcharts/:name/edit",
        element: (
          <>
            <ProtectedRoute />
            <PageTitle title="Flowcharts edit" />
            <EditCharts />
          </>
        ),
      },
    ]
  },
  {
    path: "/demos",
    // element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: "demos",
        path: "/demos",
        element: (
          <>
            <ProtectedRoute />
            <PageTitle title="Demos" />
            <Demos />
          </>
        ),
      }
    ]
  }
]);

export { router };