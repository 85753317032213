import React, { useState, useEffect } from 'react';
import { ModalWrapper } from '../molecules/modals';
import { addDemo, demoEndpoint, updateDemo, addTeam, teamEndpoint, updateTeam } from "../../api/dashboardApi";
import { slugify } from "../../helper/utils";
import { useSWRConfig } from "swr";
import { useSearchParams, useLocation } from "react-router-dom";

const DemoModal = ({
    isOpen,
    initialData,
    onClose,
    demos,
}: {
    isOpen: boolean,
    initialData: any,
    onClose: any,
    demos: any
}) => {
    const { mutate } = useSWRConfig();
    const [searchParams, _] = useSearchParams();
    let parent = searchParams.get('parent');
    let parentTitle = searchParams.get('parentTitle');
    const { pathname } = useLocation();
    let cacheKey = demoEndpoint;
    let addMethod = addDemo;
    let updateMethod = updateDemo;
    if (pathname === '/teams') {
        cacheKey = teamEndpoint;
        addMethod = addTeam;
        updateMethod = updateTeam;
    }

    const [formError, setFormError] = useState('');
    const [options, setOptions] = useState([<option key='null' value='null'>None</option>]);

    const [item, setDemo] = useState(initialData);

    useEffect(() => {
        let _init = { ...initialData, parent: parent || 'null' };
        setDemo(initialData);
    }, [parent]);


    useEffect(() => {
        let options = demos?.map((item: any) => (
            <option key={item.id} value={item.id}>{item.title}</option>
        )) || [];

        if (parent && parent !== "null") {
            options.unshift(<option key={parent} value={String(parent)}>{parent}</option>);
        }

        options.unshift(<option key="null" value="">None</option>);

        setOptions(options);
    }, [demos, isOpen]);



    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setDemo({ ...item, [name]: value });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            if (item.id) {
                const payload = {
                    ...item,
                    id: slugify(item.title),
                    // url: item.url === '' ? null : item.url,
                    parent: item.parent === '' || item.parent === 'null' ? null : item.parent
                }
                const response = await updateMethod(item.id, payload);
            } else {
                const payload = {
                    ...item,
                    id: slugify(item.title),
                    // url: item.url === '' ? null : item.url,
                    parent: item.parent === '' || item.parent === 'null' ? null : item.parent
                }
                const response = await addMethod(payload);
            }
            setFormError('');
            mutate([cacheKey, item.parent || 'null'])
            setDemo(initialData)
        } catch (err: any) {
            console.log(err);
            if (err?.response?.data?.detail) {
                setFormError(err?.response?.data?.detail)
            } else {
                setFormError("Error while creating");
            }
            return;
        }
        onClose();
    };


    if (!isOpen) return null;
    return (
        <>
            {isOpen && (<ModalWrapper>
                <form onSubmit={handleSubmit} className="mb-4">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                            Title
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="title"
                            type="text"
                            name="title"
                            required
                            value={item.title}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                            Description
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="description"
                            type="text"
                            name="description"
                            value={item.description}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="url">
                            URL
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="url"
                            type="text"
                            name="url"
                            value={item.url}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="url_type">
                            URL Type
                        </label>
                        <select
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="url_type"
                            name="url_type"
                            value={item.url_type}
                            onChange={handleInputChange}
                        >
                            <option value="external">External</option>
                            <option value="internal">Internal</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="parent">
                            Parent
                        </label>
                        <select
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="parent"
                            name="parent"
                            aria-readonly
                            value={item.parent}
                            onChange={handleInputChange}
                        >
                            {options}
                        </select>
                    </div>
                    {formError && <div role="alert" className="mb-4">
                        <div className="border border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                            <p>{formError}</p>
                        </div>
                    </div>}
                    <div className="flex items-center justify-between">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                            Save
                        </button>
                        <button type="button" className="justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </ModalWrapper>)}
        </>
    )
}

export default DemoModal;