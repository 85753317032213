import axios from 'axios';
import { BACKEND_URL, flowChartsHost } from '../constants/urls';

const backendApi = axios.create({
    baseURL: BACKEND_URL,
});

export const allFilesEndpoint = '/files';
export const flightLogsEndpoint = '/flight-logs';
export const fileDownloadEndpoint = '/files/download';


export const uploadFile = async (file: any, prefix: any = null) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    const formData = new FormData();
    if (prefix) {
        formData.append('file', file, `${prefix}/${file.name}`);
    } else {
        formData.append('file', file);
    }
    return backendApi.post('/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export const getFlightLogs = async () => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.get(flightLogsEndpoint);
}

export const getFlightLogDetail = async (parameters: any) => {
    const [logId, _] = parameters;
    if (!logId) {
        return;
    }
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.get(`${flightLogsEndpoint}/${logId}`);
}

export const addFlightLog = async (flightLog: any) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.post(flightLogsEndpoint, flightLog);
}

export const updateFlightLog = async (logId: string, flightLog: any) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.put(`${flightLogsEndpoint}/${logId}`, flightLog);
}

export const deleteFlightLog = async (logId: string) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.delete(`${flightLogsEndpoint}/${logId}`);
}

export const fetchFile = async (fileUrl: string) => {
    const params = {
        s3key: fileUrl
    }
    return backendApi.get(fileDownloadEndpoint, { params });
}

export const getAllFiles = async (parameters: any) => {
    const [project, _] = parameters;

    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        backendApi.defaults.headers.common['Content-Type'] = 'application/json';

    }
    let params = {
        extensions: '.json,.txt,.mp4',
    }
    if (project) {
        params = {
            ...params, ...{ prefix: project }
        };
    }
    return backendApi.get(allFilesEndpoint, { params });
}

export const deleteFile = async (file: string) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.delete(`/files/${file}`);
}