// @ts-nocheck


import React, { useEffect } from "react";
import { DeleteIcon } from "../atom/icons";
import Modal from '../molecules/modals';
import { BACKEND_URL } from "../../constants/urls";
import { deleteFile, allFilesEndpoint as cacheKey } from "../../api/fileApi";
import { FileContentRenderer, VideoLink } from "../molecules/FileContentRender";

const FlightLogTable: React.FC = ({ logDetail, mutate }) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [selectedFiles, setSelectedFiles] = React.useState<string[]>([]);
    const [fileToDelete, setFileToDelete] = React.useState<string | null>(null);
    const [meta, setMeta] = React.useState<any>({});


    useEffect(() => {
        if (logDetail) {
            const files = {};

            logDetail.json_files.forEach(file => {
                const name = file.split("/").pop().split(".")[0];
                files[name] = { json: file };
            });

            logDetail.remark_files.forEach(file => {
                const name = file.split("/").pop().split(".")[0];
                if (files[name]) {
                    files[name].txt = file;
                }
            });

            logDetail.video_files.forEach(file => {
                const name = file.split("/").pop().split(".")[0];
                if (files[name]) {
                    files[name].mp4 = file;
                }
            });
            setMeta(files);
        }
    }, [logDetail]);


    const handleSelect = (file: any) => {
        setSelectedFiles(prevFiles => {
            if (prevFiles.includes(file)) {
                return prevFiles.filter(f => f !== file);
            } else {
                return [...prevFiles, file];
            }
        });
    }

    const onRunClicked = (file: any) => {
        console.log('Run clicked');
        console.log(file);
        const url = `/flight-test-tracker/flight_analysis/?files=${BACKEND_URL}/files/download?s3key=${file}`;
        window.open(url, '_blank');
    }

    const onComparativeClicked = () => {
        console.log('Comparative analysis Button clicked');
        console.log(selectedFiles);
        if (selectedFiles.length < 1) {
            alert('Please select atleast a file to compare');
            return;
        }
        const files = selectedFiles.map(file => `${BACKEND_URL}/download?s3key=${file}`).join(',');
        const url = `/flight-test-tracker/compararative_analysis/?files=${files}`;
        window.open(url, '_blank');
    }

    const onDeleteClicked = async (file: string) => {
        try {
            setIsModalOpen(true)
            setFileToDelete(file);
            mutate();
        } catch (error) {
            console.log(error);
        }
    }
    const handleDeleteFile = async (event: any) => {
        event.preventDefault();
        setIsModalOpen(false);
        if (fileToDelete) {
            await deleteFile(fileToDelete);
            setFileToDelete(null);
            mutate();
        }
    }
    return (
        <>
            <div className="overflow-scroll">
                <table className="w-full table-auto">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-center">Select</th>
                            <th className="py-3 px-6 text-center">Vehicle Type</th>
                            <th className="py-3 px-6 text-center">Flight State</th>
                            <th className="py-3 px-6 text-center">Timestamp</th>
                            <th className="py-3 px-6 text-center">Recording</th>
                            <th className="py-3 px-6 text-left">Remarks</th>
                            <th className="py-3 px-6 text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                        {logDetail && logDetail?.json_files.map((file: any, index: number) => {
                            let filename = file.split("/").pop();
                            filename = filename.split('.').slice(0, -1).join('.');
                            let parts = filename.split("_");
                            const [date, time, flight_state, vehicle_type] = parts;
                            return (
                                <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                                    <td className="py-3 px-6 text-center">
                                        <input
                                            type="checkbox"
                                            checked={selectedFiles.includes(file)}
                                            onChange={() => handleSelect(file)}
                                        />
                                    </td>
                                    <td className="py-3 px-6 text-center whitespace-nowrap">{vehicle_type}</td>
                                    <td className="py-3 px-6 text-center whitespace-nowrap">{flight_state}</td>
                                    <td className="py-3 px-6 text-center whitespace-nowrap">{date} {time.replaceAll('-', ':')}</td>
                                    <td className="py-3 px-6 text-center whitespace-nowrap">
                                        {meta[filename] && <VideoLink filePath={meta[filename].mp4} />}
                                    </td>
                                    <td className="py-3 px-6 text-left whitespace-nowrap">
                                        {meta[filename] && <FileContentRenderer filePath={meta[filename].txt} />}
                                    </td>
                                    <td className="py-3 px-6 flex text-center whitespace-nowrap">
                                        <button onClick={() => onRunClicked(file)} className="bg-cyan-500 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded">
                                            Run
                                        </button>
                                        <button onClick={() => onDeleteClicked(file)} className="text-red-500 font-bold py-2 px-4 rounded">
                                            <DeleteIcon width={24} height={24} />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onConfirm={handleDeleteFile} />
            <button onClick={onComparativeClicked} className="mt-4 bg-cyan-500 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded">
                Comparative Analysis
            </button>
        </>
    )
}

export default FlightLogTable;