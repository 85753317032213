
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../atom/layout';
import Chart from '../organisms/charts';
import { getFlowchartResource } from '../../api/dashboardApi';
import { flowChartsHost } from '../../constants/urls';
import { isAxiosError } from "axios";
import useSWR from 'swr';


const Flowcharts = () => {
    const { name } = useParams();
    const navigate = useNavigate();
    const resourceUri = `${flowChartsHost}/resources/${name}.json`;
    const { isLoading, error, data, mutate } = useSWR(resourceUri, getFlowchartResource, {
        onError(err) {
            if (isAxiosError(err)) {
                if (err.response?.status === 401) {
                    console.log('Unauthorized');
                    navigate('/login');
                }
            } else {
                console.error('An unknown error occurred', err);
            }
        }
    });
    let nodes = data?.data?.nodes;
    let edges = data?.data?.edges.map((edge: any) => ({ ...edge, type: edge?.pathOptions || "default", labelBgStyle: { fill: "#e2e8f0" } }));
    return (
        <>
            <Layout />
            {!isLoading && data && <Chart initialNodes={nodes} initialEdges={edges} downloadable actionPanel />}
        </>
    );
};


export default Flowcharts;