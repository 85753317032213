// for local development
let BACKEND_URL: string = process.env.REACT_APP_BACKEND_URL || 'http://0.0.0.0:8000';
let APPS_BASE_URL: string = process.env.REACT_APP_BASE_URL || 'http://0.0.0.0';

if (process.env.REACT_APP_ENV === 'production') {
    BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://staff.mechatronic-group.com/api';
    APPS_BASE_URL = process.env.REACT_APP_BASE_URL || 'https://staff.mechatronic-group.com';
}

const webUrl: string = APPS_BASE_URL
let flowChartsHost: string = `${webUrl}:2419`
let flightTestTrackerUrl: string = `${webUrl}:8050`
let evTolPerformalyzerUrl: string = `${webUrl}:2427`

if (process.env.REACT_APP_ENV === 'production') {
    flowChartsHost = `${webUrl}/flowcharts`
    flightTestTrackerUrl = `${webUrl}/flight-test-tracker`
    evTolPerformalyzerUrl = `${webUrl}/evtol-performalyzer`
}



export { webUrl, evTolPerformalyzerUrl, flightTestTrackerUrl, flowChartsHost, BACKEND_URL, APPS_BASE_URL }