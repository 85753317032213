import axios from 'axios';
import { BACKEND_URL, flowChartsHost } from '../constants/urls';

const dashboardApi = axios.create({
    baseURL: BACKEND_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

const backendApi = axios.create({
    baseURL: BACKEND_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

// endpoints
export const dashboardEndpoint = '/dashboard';
export const flowchartsEndpoint = '/flowcharts';
export const teamEndpoint = '/teams';
export const flowchartsDetailUrlEndpoint = `${BACKEND_URL}${flowchartsEndpoint}`;
export const dashboardUrl = `${BACKEND_URL}${dashboardEndpoint}`;


export const dashboardDetails = async () => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        dashboardApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }

    return dashboardApi.get(dashboardEndpoint);
}

export const postDashboard = async (payload: any) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        dashboardApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }

    return dashboardApi.post(dashboardEndpoint, payload);
}

export const getFlowchartPages = async () => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.get(flowchartsEndpoint);
}

export const getFlowchartResource = async (resource: string) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.get(resource);

}

export const saveFlowchartResource = async (resourceUri: string, payload: any) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.post(resourceUri, payload);
}

export const getFlowchart = async (flowchartID: string) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.get(`${flowchartsEndpoint}/${flowchartID}`);
}

export const addFlowchart = async (payload: any) => {
    const tokens = localStorage.getItem('auth');
    const resourceUri = `${flowChartsHost}/resources`;

    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    const _resource = await backendApi.post(resourceUri, payload);


    const flowchartPayload = {
        id: payload?.id ? payload?.id : payload.slug,
        title: payload.title,
        parent: payload.parent,
        route: payload?.route ? payload?.route : `/${payload.slug}`,
        route_type: 'internal',
        resource: _resource.data.resource
    }
    if (payload?.id) {
        return await backendApi.put(`${flowchartsEndpoint}/${payload.id}`, flowchartPayload);
    } else {
        return await backendApi.post(flowchartsEndpoint, flowchartPayload);
    }
}

export const updateFlowchart = async (flowchartID: string, payload: any) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.put(`${flowchartsEndpoint}/${flowchartID}`, payload);

}

export const deleteFlowchart = async (flowchartID: string) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.delete(`${flowchartsEndpoint}/${flowchartID}`);

}

// export const deleteFlowchart = async (resourceUri: string) => {
//     const tokens = localStorage.getItem('auth');
//     if (tokens) {
//         let accessToken = JSON.parse(tokens).access_token;
//         backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
//     }
//     return backendApi.delete(resourceUri);
// }


// demos

export const demoEndpoint = '/demos';


export const getDemos = async (parameters: any) => {
    const [url, parent] = parameters;
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.get(demoEndpoint, { params: { parent: parent } });
}

export const getAllDemos = async () => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.get(demoEndpoint);
}

export const getDemo = async (demoID: string) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.get(`${demoEndpoint}/${demoID}`);

}


export const addDemo = async (payload: any) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.post(demoEndpoint, payload);
}

export const updateDemo = async (demoID: string, payload: any) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.put(`${demoEndpoint}/${demoID}`, payload);
}

export const deleteDemo = async (demoID: string) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.delete(`${demoEndpoint}/${demoID}`);
}


export const getTeams = async (parameters: any) => {
    const [url, parent] = parameters;
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.get(teamEndpoint, { params: { parent: parent } });
}

export const getTeam = async (teamID: string) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.get(`${teamEndpoint}/${teamID}`);

}

export const addTeam = async (payload: any) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.post(teamEndpoint, payload);
}

export const updateTeam = async (teamID: string, payload: any) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.put(`${teamEndpoint}/${teamID}`, payload);
}

export const deleteTeam = async (teamID: string) => {
    const tokens = localStorage.getItem('auth');
    if (tokens) {
        let accessToken = JSON.parse(tokens).access_token;
        backendApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return backendApi.delete(`${teamEndpoint}/${teamID}`);
}