import React, { useState } from "react";
import useSWR from 'swr';
import { fetchFile, fileDownloadEndpoint } from "../../api/fileApi";
import { BACKEND_URL } from "../../constants/urls";
import { Link } from "react-router-dom";

interface FileContentRenderProps {
    filePath: string;
}

export const FileContentRenderer: React.FC<FileContentRenderProps> = ({ filePath }) => {

    const { isLoading, error, data: content, mutate } = useSWR(filePath, fetchFile, {
        onError(err) {
            console.log('Error', err);
        }
    });
    if (isLoading) return <p>Loading...</p>
    if (content?.data) {
        const sentences = content.data.split('. ');
        return (
            <>
                <p>{sentences[0]}.</p>
                <p>{sentences.slice(1).join('. ')}</p>
            </>
        )
    }
    return null;
}


export const VideoLink: React.FC<FileContentRenderProps> = ({ filePath }) => {
    const [showModal, setShowModal] = useState(false);


    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    if (!filePath) return null;
    const videoUrl = `${BACKEND_URL}${fileDownloadEndpoint}?s3key=${filePath}`;

    return (
        <>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleOpenModal}>Play</button>
            {showModal && (
                <div className="fixed inset-0 sm:ml-64 mt-16 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="relative w-4/5 h-4/5 bg-white">
                        <button className="absolute top-2 right-2 z-10 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded" onClick={handleCloseModal}>Close</button>
                        <video controls autoPlay className="w-full h-full">
                            <source src={videoUrl} type="video/mp4" />
                        </video>
                    </div>
                </div>
            )}
        </>
    );
};