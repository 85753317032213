// @ts-nocheck

import React, { useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DeleteIcon, EditIcon } from "../atom/icons";
import { useSWRConfig } from "swr";
import { deleteDemo, deleteTeam, demoEndpoint, teamEndpoint } from "../../api/dashboardApi";
import Modal from "./modals";
import DemoModal from "../organisms/editDemoModal";
import path from "path";

interface LinkCardsProps {
    content?: any;
    loading?: boolean
    readOnly?: boolean
}

const LinkCards: React.FC<LinkCardsProps> = ({ content, loading }) => {
    return (
        <>
            <div className="grid grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 mb-4">
                {loading && <div className="animate-pulse bg-gray-200 dark:bg-gray-700 h-48 rounded-lg"></div>}
                {content && Object.keys(content).map(
                    (key) => {
                        const item = content[key];
                        let type;
                        let route;
                        let target;

                        type = item?.url_type || item?.type;
                        route = item?.path ? item.path : item.url;
                        target = (type === "external") ? "_blank" : undefined;

                        if (route === undefined || route === null || route === '') {
                            type = "internal";
                            route = `${pathname}?parent=${item.id}&parentTitle=${item.title}`;
                            target = undefined;
                        }
                        return (
                            <Link key={key} to={route} target={target} className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                                <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">{content[key].title}</h5>
                                {/* {content[key].description && <p className="font-normal text-gray-700 dark:text-gray-400">{content[key].description}</p>} */}
                            </Link>
                        )
                    }
                )}
            </div>
        </>
    )
}

export const DemoLinkCards: React.FC<LinkCardsProps> = ({ content, readOnly, loading }) => {
    const { mutate } = useSWRConfig()
    const { pathname } = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedDemo, setSelectedDemo] = useState();
    const [error, setError] = useState("")

    let cacheKey = demoEndpoint;
    let deleteMethod = deleteDemo;
    if (pathname === '/teams') {
        cacheKey = teamEndpoint;
        deleteMethod = deleteTeam;
    }

    const handleDelete = useCallback((event: React.MouseEvent, item: any) => {
        event.stopPropagation();
        event.preventDefault();
        setSelectedDemo(item);
        setIsModalOpen(true);
    }, [selectedDemo, isModalOpen, isEditModalOpen])

    const handleEdit = useCallback((event: React.MouseEvent, item: any) => {
        event.stopPropagation();
        event.preventDefault();
        setSelectedDemo(item);
        setIsEditModalOpen(true);
    }, [selectedDemo, isModalOpen, isEditModalOpen])

    const confirmDelete = useCallback(async () => {
        if (selectedDemo) {
            try {
                await deleteMethod(selectedDemo?.id);
                mutate([cacheKey, selectedDemo?.parent || 'null']);
                setError('')
            } catch (err) {
                console.log(err);
                setError("Error while deleting item")
                return;
            }
        }
        setIsModalOpen(false);
    }, [selectedDemo])

    return (
        <>
            <div className="grid grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 mb-4">
                {loading && <div className="animate-pulse bg-gray-200 dark:bg-gray-700 h-48 rounded-lg"></div>}
                {error && <div role="alert" className="mb-4">
                    <div className="border border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                        <p>{error}</p>
                    </div>
                </div>}
                {selectedDemo && <DemoModal
                    key={`${selectedDemo?.id}-edit`}
                    isOpen={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    initialData={selectedDemo}
                    options={(<option key={selectedDemo.id} value={selectedDemo.parent}>{selectedDemo.parent}</option>)}
                />}
                {
                    content && content.map((item: any) => {
                        let type;
                        let route;
                        let target;

                        type = item?.url_type || item?.type;
                        route = item?.path ? item.path : item.url;
                        target = (type === "external") ? "_blank" : undefined;

                        if (route === undefined || route === null || route === '') {
                            type = "internal";
                            route = `${pathname}?parent=${item.id}&parentTitle=${item.title}`;
                            target = undefined;
                        }
                        return (
                            <React.Fragment key={item.id}>
                                <Link key={`${item.id}-${item.parent}`} to={route} target={target} className="relative block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                                    <h5 key={`${item.id}-item`} className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">{item.title}</h5>
                                    {!readOnly && <div className="absolute bottom-0 right-0">
                                        <button key={`${item.id}-edit`} onClick={(event) => handleEdit(event, item)} className="text-red-500 font-bold py-1 px-2 rounded hover:bg-gray-200 focus:outline-none focus:shadow-outline"><EditIcon width={20} height={20} /></button>
                                        <button key={`${item.id}-delete`} onClick={(event) => handleDelete(event, item)} className="text-red-500 font-bold py-1 px-2 rounded hover:bg-gray-200 focus:outline-none focus:shadow-outline"><DeleteIcon width={20} height={20} /></button>
                                    </div>}
                                </Link>

                            </React.Fragment>
                        )
                    }
                    )
                }
                <Modal
                    key={`${selectedDemo?.id}-delete`}
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onConfirm={confirmDelete}
                    description="The data will be permanently removed affecting its child items. This action cannot be undone."
                >
                </Modal>
            </div>
        </>
    )
}


export default LinkCards;
