// @ts-nocheck

import React, { useEffect } from "react";
import Layout from "../atom/layout";
import { useParams, useNavigate } from "react-router-dom";
import { getFlightLogDetail, addFlightLog, updateFlightLog, allFilesEndpoint as cacheKey } from "../../api/fileApi";
import useSWR from 'swr';
import axios from "axios";
import { slugify } from "../../helper/utils";


const EditFlightLog: React.FC = () => {
    const { logId } = useParams();
    const navigate = useNavigate();
    const [flightLog, setFlightLog] = React.useState({ title: null, bucket_prefix: null });

    const { isLoading, error, data: fileContent, mutate } = useSWR([logId, cacheKey], getFlightLogDetail, {
        onError(err) {
            if (axios.isAxiosError(err)) {
                if (err.response?.status === 401) {
                    console.log('Unauthorized');
                    navigate('/login');
                }
            }
        }
    });

    useEffect(() => {
        if (fileContent && fileContent.data) {
            setFlightLog({ ...flightLog, ...fileContent.data });
        }
    }, [fileContent, logId]);


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFlightLog({ ...flightLog, [name]: value });
    };

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            if (logId) {
                let payload = { ...flightLog, id: logId };
                const response = await updateFlightLog(logId, payload);
                setFlightLog({ title: null, bucket_prefix: null });
                navigate(`/web-apps/flight-test-tracker/${response.data.id}`);
            } else {
                let payload = { ...flightLog, id: slugify(flightLog.title) };
                const response = await addFlightLog(payload);
                setFlightLog({ title: null, bucket_prefix: null });
                navigate(`/web-apps/flight-test-tracker/${response.data.id}`);
            }
            mutate({ ...fileContent, ...response?.data });
        } catch (err: any) {
            console.log(err);
        }
    }
    const goBack = () => {
        navigate(-1);
    }

    return (
        <>
            <Layout />
            <div className="p-4 sm:ml-64 mt-16">
                <div className="p-4 mt-4 rounded-lg border dark:border-gray-700">
                    <form onSubmit={handleFormSubmit} className="mb-4 ml-4">
                        <div className="space-y-6">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">{logId ? "Update" : "Create"} Flight Log</h2>
                            <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                                        Title
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                type="text"
                                                name="title"
                                                required
                                                value={flightLog.title || ''}
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                placeholder="Enter title"
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:col-span-4">
                                    <label htmlFor="bucket_prefix" className="block text-sm font-medium leading-6 text-gray-900">
                                        Bucket Prefix
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                type="text"
                                                name="bucket_prefix"
                                                required
                                                value={flightLog.bucket_prefix || ''}
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                placeholder="Enter bucket prefix"
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 flex items-center justify-left gap-x-6">
                            <button
                                type="submit"
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Save
                            </button>
                            <button onClick={() => goBack()} className="text-sm font-semibold leading-6 text-gray-900">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default EditFlightLog;
